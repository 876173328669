@media screen and (min-width: 1200px) {
  .main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    //background-color: rgba(255,255,255,0.1);
    background-image: url("./logowater.svg");
    background-size: 100%;
    background-attachment: fixed;
    background-position: 70% center;
    background-repeat: no-repeat;
    .container{
      width: calc(100% - 60px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .alone{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .blockabout{
          width: 70%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 100px;
          color: #FFFFFF;
          font-size: 16pt;
          z-index: 1;
          .up{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .title{
              color: #ffffff;
              width: calc(100% - 200px);
              display: flex;
              font-size: 30pt;
              text-transform: uppercase;
              margin-bottom: 20px;
              padding-left: 130px;
              opacity: 1;
              transform: translateX(0%);
              transition: opacity 0.6s ease, transform 0.6s ease;
            }
          }
          .bot{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-top: 30px;
            .block{
              width: calc(100% - 130px);
              height: 100%;
              padding-left: 130px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              .desc{
                margin-bottom: 20px;
                font-size: 20pt;
                text-align: justify;
              }
            }

          }

        }
        .imgs{
          width: 30%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 100px;
          z-index: 0;
          .one{
            width: 30%;
            animation: one 3s ease-in-out infinite;
          }
          @keyframes one {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(10px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          .two{
            width: 40%;
            margin-left: 0px;
            animation: two 3s ease-in-out infinite;
          }
          @keyframes two {
            0% {
              transform: translateX(0) translateY(-5px) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(20px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(-5px) rotate(0deg);
            }
          }
        }
      }
      .scopes{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 100px;
        .name{
          color: #ffffff;
          width: calc(100% - 200px);
          display: flex;
          font-size: 30pt;
          text-transform: uppercase;
          margin-bottom: 20px;
          padding-left: 130px;
          opacity: 1;
          transform: translateX(0%);
          transition: opacity 0.6s ease, transform 0.6s ease;


        }
        .points{
          width: calc(100% - 260px);
          height: 100%;
          margin: 50px 130px 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: flex-start;
          .bidscheck{
            width: calc(50% - 68px);
            margin: 10px;
            padding: 20px;
            min-height: 100px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #FFF;
            border: 4px solid #FFF;
            font-size: 18pt;
          }
          .lab1{
            background-color: rgba(90, 44, 159, 0.56);
          }
          .lab2{
            background-color: rgba(107, 44, 159, 0.56);
          }
          .lab3{
            background-color: rgba(134, 44, 159, 0.56);
          }
          .lab4{
            background-color: rgba(157, 19, 134, 0.56);
          }
        }

      }

    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    //background-color: rgba(255,255,255,0.1);
    background-image: url("./logowater.svg");
    background-size: 100%;
    background-attachment: fixed;
    background-position: 70% center;
    background-repeat: no-repeat;
    .container{
      width: calc(100% - 60px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .alone{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        .blockabout{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 100px;
          color: #FFFFFF;
          font-size: 16pt;
          z-index: 1;
          .up{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .title{
              color: #ffffff;
              width: calc(100% - 0px);
              display: flex;
              justify-content: center;
              font-size: 30pt;
              text-transform: uppercase;
              margin-bottom: 20px;
              padding-left: 0px;
              opacity: 1;
              transform: translateX(0%);
              transition: opacity 0.6s ease, transform 0.6s ease;
            }
          }
          .bot{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-top: 30px;
            .block{
              width: calc(100% - 60px);
              height: 100%;
              padding-left: 20px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              .desc{
                margin-bottom: 20px;
                font-size: 20pt;
                text-align: justify;
              }
            }

          }

        }
        .imgs{
          position: absolute;
          top: 0;
          right: 0;
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 100px;
          z-index: 0;
          .one{
            width: 40%;
            animation: one 3s ease-in-out infinite;
          }
          @keyframes one {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(10px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          .two{
            width: 50%;
            margin-left: 0px;
            animation: two 3s ease-in-out infinite;
          }
          @keyframes two {
            0% {
              transform: translateX(0) translateY(-5px) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(20px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(-5px) rotate(0deg);
            }
          }
        }
      }
      .scopes{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 70px;
        .name{
          color: #ffffff;
          width: calc(100% - 0px);
          display: flex;
          justify-content: center;
          font-size: 30pt;
          text-transform: uppercase;
          margin-bottom: 20px;
          padding-left: 0px;
          opacity: 1;
          transform: translateX(0%);
          transition: opacity 0.6s ease, transform 0.6s ease;


        }
        .points{
          width: calc(100% - 60px);
          height: 100%;
          margin: 50px 30px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          .bidscheck{
            width: calc(100% - 48px);
            margin: 10px;
            padding: 20px;
            min-height: 100px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #FFF;
            border: 4px solid #FFF;
            font-size: 18pt;
          }
          .lab1{
            background-color: rgba(90, 44, 159, 0.56);
          }
          .lab2{
            background-color: rgba(107, 44, 159, 0.56);
          }
          .lab3{
            background-color: rgba(134, 44, 159, 0.56);
          }
          .lab4{
            background-color: rgba(157, 19, 134, 0.56);
          }
        }

      }

    }
  }
}
@media screen and (min-width: 550px) and (max-width: 900px) {
  .main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    //background-color: rgba(255,255,255,0.1);
    background-image: url("./logowater.svg");
    background-size: 100%;
    background-attachment: fixed;
    background-position: 70% center;
    background-repeat: no-repeat;
    .container{
      width: calc(100% - 60px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .alone{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        .blockabout{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 100px;
          color: #FFFFFF;
          font-size: 16pt;
          z-index: 1;
          .up{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .title{
              color: #ffffff;
              width: calc(100% - 0px);
              display: flex;
              justify-content: center;
              font-size: 30pt;
              text-transform: uppercase;
              margin-bottom: 20px;
              padding-left: 0px;
              opacity: 1;
              transform: translateX(0%);
              transition: opacity 0.6s ease, transform 0.6s ease;
            }
          }
          .bot{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-top: 30px;
            .block{
              width: calc(100% - 60px);
              height: 100%;
              padding-left: 20px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              .desc{
                margin-bottom: 20px;
                font-size: 16pt;
                //text-align: justify;
              }
            }

          }

        }
        .imgs{
          position: absolute;
          top: 0;
          right: 0;
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 100px;
          z-index: 0;
          .one{
            width: 40%;
            animation: one 3s ease-in-out infinite;
          }
          @keyframes one {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(10px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          .two{
            width: 50%;
            margin-left: 0px;
            animation: two 3s ease-in-out infinite;
          }
          @keyframes two {
            0% {
              transform: translateX(0) translateY(-5px) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(20px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(-5px) rotate(0deg);
            }
          }
        }
      }
      .scopes{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 70px;
        .name{
          color: #ffffff;
          width: calc(100% - 0px);
          display: flex;
          justify-content: center;
          font-size: 30pt;
          text-transform: uppercase;
          margin-bottom: 20px;
          padding-left: 0px;
          opacity: 1;
          transform: translateX(0%);
          transition: opacity 0.6s ease, transform 0.6s ease;
          text-align: center;


        }
        .points{
          width: calc(100% - 0px);
          height: 100%;
          margin: 30px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          .bidscheck{
            width: calc(100% - 48px);
            margin: 10px 0;
            padding: 20px;
            min-height: 100px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #FFF;
            border: 4px solid #FFF;
            font-size: 18pt;
          }
          .lab1{
            background-color: rgba(90, 44, 159, 0.56);
          }
          .lab2{
            background-color: rgba(107, 44, 159, 0.56);
          }
          .lab3{
            background-color: rgba(134, 44, 159, 0.56);
          }
          .lab4{
            background-color: rgba(157, 19, 134, 0.56);
          }
        }

      }

    }
  }
}
@media screen and (min-width: 370px) and (max-width: 550px) {
  .main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    //background-color: rgba(255,255,255,0.1);
    background-image: url("./logowater.svg");
    background-size: 200%;
    background-attachment: fixed;
    background-position: 100% 20%;
    background-repeat: no-repeat;
    .container{
      width: calc(100% - 60px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .alone{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        .blockabout{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 100px;
          color: #FFFFFF;
          font-size: 16pt;
          z-index: 1;
          .up{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .title{
              color: #ffffff;
              width: calc(100% - 0px);
              display: flex;
              justify-content: center;
              font-size: 30pt;
              text-transform: uppercase;
              margin-bottom: 20px;
              padding-left: 0px;
              opacity: 1;
              transform: translateX(0%);
              transition: opacity 0.6s ease, transform 0.6s ease;
            }
          }
          .bot{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;
            .block{
              width: calc(100% - 0px);
              height: 100%;
              padding-left: 0px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              .desc{
                margin-bottom: 20px;
                font-size: 14pt;
                //text-align: justify;
                text-align: center;
              }
            }

          }

        }
        .imgs{
          position: absolute;
          top: -100px;
          right: 0;
          width: 70%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 100px;
          z-index: 0;
          .one{
            width: 40%;
            animation: one 3s ease-in-out infinite;
          }
          @keyframes one {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(10px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          .two{
            width: 50%;
            margin-left: 0px;
            animation: two 3s ease-in-out infinite;
          }
          @keyframes two {
            0% {
              transform: translateX(0) translateY(-5px) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(20px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(-5px) rotate(0deg);
            }
          }
        }
      }
      .scopes{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 70px;
        .name{
          color: #ffffff;
          width: calc(100% - 0px);
          display: flex;
          justify-content: center;
          font-size: 30pt;
          text-transform: uppercase;
          margin-bottom: 20px;
          padding-left: 0px;
          opacity: 1;
          transform: translateX(0%);
          transition: opacity 0.6s ease, transform 0.6s ease;
          text-align: center;


        }
        .points{
          width: calc(100% - 0px);
          height: 100%;
          margin: 30px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          .bidscheck{
            width: calc(100% - 48px);
            margin: 10px 0;
            padding: 20px;
            min-height: 60px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #FFF;
            border: 4px solid #FFF;
            font-size: 14pt;
          }
          .lab1{
            background-color: rgba(90, 44, 159, 0.56);
          }
          .lab2{
            background-color: rgba(107, 44, 159, 0.56);
          }
          .lab3{
            background-color: rgba(134, 44, 159, 0.56);
          }
          .lab4{
            background-color: rgba(157, 19, 134, 0.56);
          }
        }

      }

    }
  }
}
@media screen and (min-width: 100px) and (max-width: 370px) {
  .main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    //background-color: rgba(255,255,255,0.1);
    background-image: url("./logowater.svg");
    background-size: 200%;
    background-attachment: fixed;
    background-position: 100% 20%;
    background-repeat: no-repeat;
    .container{
      width: calc(100% - 60px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .alone{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        .blockabout{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 100px;
          color: #FFFFFF;
          font-size: 16pt;
          z-index: 1;
          .up{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .title{
              color: #ffffff;
              width: calc(100% - 0px);
              display: flex;
              justify-content: center;
              font-size: 30pt;
              text-transform: uppercase;
              margin-bottom: 20px;
              padding-left: 0px;
              opacity: 1;
              transform: translateX(0%);
              transition: opacity 0.6s ease, transform 0.6s ease;
            }
          }
          .bot{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;
            .block{
              width: calc(100% - 0px);
              height: 100%;
              padding-left: 0px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              .desc{
                margin-bottom: 20px;
                font-size: 12pt;
                //text-align: justify;
                text-align: center;
              }
            }

          }

        }
        .imgs{
          position: absolute;
          top: -100px;
          right: 0;
          width: 70%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 100px;
          z-index: 0;
          .one{
            width: 40%;
            animation: one 3s ease-in-out infinite;
          }
          @keyframes one {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(10px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          .two{
            width: 50%;
            margin-left: 0px;
            animation: two 3s ease-in-out infinite;
          }
          @keyframes two {
            0% {
              transform: translateX(0) translateY(-5px) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(20px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(-5px) rotate(0deg);
            }
          }
        }
      }
      .scopes{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 70px;
        .name{
          color: #ffffff;
          width: calc(100% - 0px);
          display: flex;
          justify-content: center;
          font-size: 20pt;
          text-transform: uppercase;
          margin-bottom: 20px;
          padding-left: 0px;
          opacity: 1;
          transform: translateX(0%);
          transition: opacity 0.6s ease, transform 0.6s ease;
          text-align: center;


        }
        .points{
          width: calc(100% - 0px);
          height: 100%;
          margin: 30px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          .bidscheck{
            width: calc(100% - 48px);
            margin: 10px 0;
            padding: 20px;
            min-height: 60px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #FFF;
            border: 4px solid #FFF;
            font-size: 12pt;
          }
          .lab1{
            background-color: rgba(90, 44, 159, 0.56);
          }
          .lab2{
            background-color: rgba(107, 44, 159, 0.56);
          }
          .lab3{
            background-color: rgba(134, 44, 159, 0.56);
          }
          .lab4{
            background-color: rgba(157, 19, 134, 0.56);
          }
        }

      }

    }
  }
}

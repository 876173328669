@media screen and (min-width: 900px){
  .main{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #B784FC;
    .container{
      width: calc(100% - 100px);
      max-width: calc(1920px - 100px);
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #B784FC;
      box-shadow: 2px 5px 100px rgba(255, 3, 3, 0.28);
      padding: 50px 50px 50px 50px;
      color: #FFF;
      .left{

        .nav{
          display: flex;
        }
        .line{
          margin-right: 20px;
          font-size: 20pt;
          cursor: pointer;
          transition: 0.2s;
          color: #FFF;
        }

        .line:hover{
          text-shadow: 0px 0px 15px rgba(229, 0, 88, 0.83);
          transition: 0.2s;
        }
      }

      .follow{
        display: flex;
        margin-right: 50px;
        align-items: center;
        .title{
          font-size: 20pt;
          margin-right: 20px;
        }
        .icons{
          i{
            font-size: 40pt;
            margin-right: 20px;
            color: #FFF;
            transition: 0.2s;
          }
          i:hover{
            text-shadow: 0px 0px 15px rgba(229, 0, 88, 0.83);
            transition: 0.2s;
          }
        }
      }

    }
  }
}
@media screen and (min-width: 630px) and (max-width: 900px){
  .main{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #B784FC;
    .container{
      width: calc(100% - 100px);
      max-width: calc(1920px - 100px);
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #B784FC;
      box-shadow: 2px 5px 100px rgba(255, 3, 3, 0.28);
      padding: 50px 50px 50px 50px;
      color: #FFF;
      .left{

        .nav{
          display: flex;
        }
        .line{
          margin-right: 20px;
          font-size: 14pt;
          cursor: pointer;
          transition: 0.2s;
          color: #FFF;
        }

        .line:hover{
          text-shadow: 0px 0px 15px rgba(229, 0, 88, 0.83);
          transition: 0.2s;
        }
      }

      .follow{
        display: flex;
        margin-right: -20px;
        align-items: center;
        .title{
          font-size: 14pt;
          margin-right: 20px;
        }
        .icons{
          i{
            font-size: 30pt;
            margin-right: 20px;
            color: #FFF;
            transition: 0.2s;
          }
          i:hover{
            text-shadow: 0px 0px 15px rgba(229, 0, 88, 0.83);
            transition: 0.2s;
          }
        }
      }

    }
  }
}
@media screen and (min-width: 500px) and (max-width: 630px){
  .main{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #B784FC;
    .container{
      width: calc(100% - 100px);
      max-width: calc(1920px - 100px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: #B784FC;
      box-shadow: 2px 5px 100px rgba(255, 3, 3, 0.28);
      padding: 50px 50px 50px 50px;
      color: #FFF;
      .left{
        margin-bottom: 30px;
        .nav{
          display: flex;
        }
        .line{
          margin: 10px;
          font-size: 14pt;
          cursor: pointer;
          transition: 0.2s;
          color: #FFF;
        }

        .line:hover{
          text-shadow: 0px 0px 15px rgba(229, 0, 88, 0.83);
          transition: 0.2s;
        }
      }

      .follow{
        display: flex;
        margin-right: -20px;
        align-items: center;
        .title{
          font-size: 14pt;
          margin-right: 20px;
        }
        .icons{
          i{
            font-size: 30pt;
            margin-right: 20px;
            color: #FFF;
            transition: 0.2s;
          }
          i:hover{
            text-shadow: 0px 0px 15px rgba(229, 0, 88, 0.83);
            transition: 0.2s;
          }
        }
      }

    }
  }
}
@media screen and (min-width: 100px) and (max-width: 500px){
  .main{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #B784FC;
    .container{
      width: calc(100% - 100px);
      max-width: calc(1920px - 100px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: #B784FC;
      box-shadow: 2px 5px 100px rgba(255, 3, 3, 0.28);
      padding: 50px 50px 50px 50px;
      color: #FFF;
      .left{

        margin-bottom: 30px;
        .nav{
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .line{
          margin: 10px;
          font-size: 14pt;
          cursor: pointer;
          transition: 0.2s;
          color: #FFF;
        }

        .line:hover{
          text-shadow: 0px 0px 15px rgba(229, 0, 88, 0.83);
          transition: 0.2s;
        }
      }

      .follow{
        display: flex;
        margin-right: -20px;
        align-items: center;
        .title{
          font-size: 14pt;
          margin-right: 20px;
        }
        .icons{
          i{
            font-size: 30pt;
            margin-right: 20px;
            color: #FFF;
            transition: 0.2s;
          }
          i:hover{
            text-shadow: 0px 0px 15px rgba(229, 0, 88, 0.83);
            transition: 0.2s;
          }
        }
      }

    }
  }
}

.main{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B784FC;
  .container{
    width: calc(100% - 100px);
    max-width: calc(1920px - 100px);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #B784FC;
    box-shadow: 2px 5px 100px rgba(255, 3, 3, 0.28);
    padding: 200px 50px 100px 50px;
    color: #FFF;
    .left{
      width: 40%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .nav{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 50%;
        .title{
          font-size: 40pt;
          border-bottom: 4px solid #FFF;
          margin-bottom: 40px;
        }
        .line{
          font-size: 20pt;
          margin-bottom: 20px;
          cursor: pointer;
          color: #FFF;
        }
      }
    }


    .follow{
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      .title{
        font-size: 40pt;
      }
      .form{
        width: 70%;
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
        input{
          border: 4px solid #FFF;
          background-color: rgba(0,0,0,0);
          outline: none;
          width: calc(100% - 88px);
          height: 60px;
          padding: 0 10px;
          font-size: 20px;
          color: #FFF;
        }
        .btn{
          width: 68px;
          height: 68px;
          background-color: rgba(255,255,255,0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20pt;
          text-shadow: 2px 2px 2px rgba(0,0,0,0.2);
          cursor: pointer;
        }
      }
      .us{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 50px;
        .title{

          font-size: 20pt;
        }
        .icons{
          font-size: 40pt;
          width: 60%;
          display: flex;
          justify-content: space-between;
          margin-left: 50px;
        }
      }
    }
  }
}

.bid {
  opacity: 0;
  transform: translateY(50px); // Начальная позиция (вылет снизу)
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0); // Финальная позиция
  }
}
@media screen and (min-width: 1560px){
  .main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 100px;
    .title{
      color: #ffffff;
      width: calc(100% - 200px);
      display: flex;
      font-size: 30pt;
      text-transform: uppercase;
      margin-bottom: 20px;
      padding-left: 130px;
      opacity: 1;
    }
    .scope{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 50px;
      .bid{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(30% - 40px);
        margin: 20px;
        border-bottom: 1px solid #FFF;
        color: #FFF;
        .title{
          font-size: 24px;
          font-weight: 600;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 0;
          margin: 20px 0 0 0;
        }

        i{
          font-size: 50pt;
          margin-top: 30px;
        }
        .desc{
          min-height: 120px;
          font-size: 18pt;
          margin-top: 30px;
          text-align: center;
          margin-bottom: 20px;
          color: #F1F1F1;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1560px){
  .main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 100px;
    .title{
      color: #ffffff;
      width: calc(100% - 200px);
      display: flex;
      font-size: 30pt;
      text-transform: uppercase;
      margin-bottom: 20px;
      padding-left: 130px;
      opacity: 1;
    }
    .scope{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 50px;
      .bid{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(50% - 40px);
        margin: 20px;
        border-bottom: 1px solid #FFF;
        color: #FFF;
        .title{
          font-size: 24px;
          font-weight: 600;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 0;
          margin: 20px 0 0 0;
        }

        i{
          font-size: 50pt;
          margin-top: 30px;
        }
        .desc{
          min-height: 120px;
          font-size: 18pt;
          margin-top: 30px;
          text-align: center;
          margin-bottom: 20px;
          color: #F1F1F1;
        }
      }
    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1200px){
  .main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 100px;
    .title{
      color: #ffffff;
      width: calc(100% - 0px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30pt;
      text-transform: uppercase;
      margin-bottom: 20px;
      //padding-left: 130px;
      opacity: 1;
    }
    .scope{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 50px;
      .bid{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(50% - 40px);
        margin: 20px;
        border-bottom: 1px solid #FFF;
        color: #FFF;
        .title{
          font-size: 20px;
          font-weight: 600;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 0;
          margin: 20px 0 0 0;
        }

        i{
          font-size: 40pt;
          margin-top: 30px;
        }
        .desc{
          min-height: 120px;
          font-size: 14pt;
          margin-top: 30px;
          text-align: center;
          margin-bottom: 0px;
          color: #F1F1F1;
        }
      }
    }
  }
}
@media screen and (min-width: 550px) and (max-width: 900px){
  .main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 100px;
    .title{
      color: #ffffff;
      width: calc(100% - 0px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30pt;
      text-transform: uppercase;
      margin-bottom: 20px;
      //padding-left: 130px;
      opacity: 1;
    }
    .scope{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 50px;
      .bid{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 40px);
        margin: 20px;
        border-bottom: 1px solid #FFF;
        color: #FFF;
        .title{
          font-size: 20px;
          font-weight: 600;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 0;
          margin: 20px 0 0 0;
        }

        i{
          font-size: 40pt;
          margin-top: 30px;
        }
        .desc{
          min-height: 120px;
          font-size: 14pt;
          margin-top: 30px;
          text-align: center;
          margin-bottom: 0px;
          color: #F1F1F1;
        }
      }
    }
  }
}
@media screen and (min-width: 100px) and (max-width: 550px){
  .main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 100px;
    .title{
      color: #ffffff;
      width: calc(100% - 0px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20pt;
      text-transform: uppercase;
      margin-bottom: 20px;
      //padding-left: 130px;
      opacity: 1;
    }
    .scope{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 50px;
      @media screen and (min-width: 370px){
        .bid{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: calc(100% - 40px);
          margin: 20px;
          border-bottom: 1px solid #FFF;
          color: #FFF;
          height: 100%;
          .title{
            font-size: 20px;
            font-weight: 600;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 0;
            margin: 20px 0 0 0;
          }

          i{
            font-size: 40pt;
            margin-top: 30px;
          }
          .desc{
            min-height: 120px;
            font-size: 14pt;
            margin-top: 30px;
            text-align: center;
            margin-bottom: -10px;
            color: #F1F1F1;
          }
        }
      }
      @media screen and (max-width: 370px){
        .bid{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: calc(100% - 40px);
          margin: 20px;
          border-bottom: 1px solid #FFF;
          color: #FFF;
          height: 100%;

          .title{
            font-size: 20px;
            font-weight: 600;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 0;
            margin: 20px 0 0 0;
          }

          i{
            font-size: 40pt;
            margin-top: 30px;
          }
          .desc{
            min-height: 120px;
            font-size: 14pt;
            margin-top: 30px;
            text-align: center;
            margin-bottom: 20px;
            color: #F1F1F1;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    background-color: rgba(255,255,255,0.1);
    .container{
      width: calc(100% - 60px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .blockabout{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 100px;
        color: #FFFFFF;
        font-size: 16pt;
      }


      .left{
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .block{}
        .desc{
          text-align: justify;
        }
      }

      .right{
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .block{
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      .title{
        color: #ffffff;
        width: calc(100% - 200px);
        display: flex;
        font-size: 30pt;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-left: 130px;
        opacity: 1;
        transform: translateX(0%);
        transition: opacity 0.6s ease, transform 0.6s ease;
      }
      .desc{
        margin-bottom: 20px;
        font-size: 20pt;
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    background-color: rgba(255,255,255,0.1);
    .container{
      width: calc(100% - 60px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .blockabout{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 100px;
        color: #FFFFFF;
        font-size: 16pt;
      }


      .left{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .block{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
        }
        .desc{
          width: 65%;
          text-align: justify;
        }
      }

      .right{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .block{
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: -60px;
      }
      .title{
        color: #ffffff;
        width: calc(100% - 200px);
        display: flex;
        font-size: 30pt;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-left: 120px;
        opacity: 1;
        transform: translateX(0%);
        transition: opacity 0.6s ease, transform 0.6s ease;
      }
      .desc{
        margin-bottom: 20px;
        font-size: 16pt;
        padding-right: 30px;
      }
    }
  }
}
@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    background-color: rgba(255,255,255,0.1);
    .container{
      width: calc(100% - 60px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .blockabout{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 100px;
        color: #FFFFFF;
        font-size: 16pt;
      }


      .left{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .block{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
        }
        .desc{
          width: 65%;
          text-align: justify;
        }
      }
      .right{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .block{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: -63px;
      }
      .title{
        color: #ffffff;
        width: calc(100% - 200px);
        display: flex;
        font-size: 30pt;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-left: 120px;
        opacity: 1;
        transform: translateX(0%);
        transition: opacity 0.6s ease, transform 0.6s ease;
      }
      .desc{
        margin-bottom: 20px;
        font-size: 14pt;
        padding-right: 30px;
      }
    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1100px) {
  .main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    background-color: rgba(255,255,255,0.1);
    .container{
      width: calc(100% - 60px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .blockabout{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 100px;
        color: #FFFFFF;
        font-size: 16pt;
      }


      .left{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .block{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
        }
        .desc{
          width: 90%;
          text-align: justify;
        }
      }
      .right{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .block{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      .title{
        color: #ffffff;
        width: calc(100%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30pt;
        text-transform: uppercase;
        margin-bottom: 20px;
        opacity: 1;
        transform: translateX(0%);
        transition: opacity 0.6s ease, transform 0.6s ease;
      }
      .desc{
        margin-bottom: 20px;
        font-size: 14pt;
      }
    }
  }
}
@media screen and (min-width: 550px) and (max-width: 900px) {
  .main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    background-color: rgba(255,255,255,0.1);
    .container{
      width: calc(100% - 60px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .blockabout{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 100px;
        color: #FFFFFF;
        font-size: 16pt;
      }


      .left{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .block{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
        }
        .desc{
          width: 90%;
          text-align: justify;
        }
      }
      .right{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .block{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      .title{
        color: #ffffff;
        width: calc(100%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30pt;
        text-transform: uppercase;
        margin-bottom: 20px;
        opacity: 1;
        transform: translateX(0%);
        transition: opacity 0.6s ease, transform 0.6s ease;
      }
      .desc{
        margin-bottom: 20px;
        font-size: 14pt;
      }
    }
  }
}
@media screen and (min-width: 100px) and (max-width: 550px) {
  .main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    background-color: rgba(255,255,255,0.1);
    .container{
      width: calc(100% - 60px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .blockabout{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 100px;
        color: #FFFFFF;
        font-size: 16pt;
      }


      .left{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .block{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
        }
        .desc{
          width: 90%;
          //text-align: justify;
          text-align: center;
        }
      }
      .right{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .block{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      .title{
        color: #ffffff;
        width: calc(100%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30pt;
        text-transform: uppercase;
        margin-bottom: 20px;
        opacity: 1;
        transform: translateX(0%);
        transition: opacity 0.6s ease, transform 0.6s ease;
      }
      .desc{
        margin-bottom: 20px;
        font-size: 14pt;
      }
    }
  }
}

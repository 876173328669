@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  /*outline: 1px solid red;*/

}

.bodymain{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  text-decoration: none;
}
/* Основные стили */
.audioPlayer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

/* Маленький плеер */
.smallPlayer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.smallPlayer img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.smallPlayer .trackInfo {
  flex: 1;
  margin-left: 10px;
}

.smallPlayer .trackInfo div {
  font-size: 14px;
  color: #333;
  margin-bottom: 4px;
}

.smallPlayer button {
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.smallPlayer button:hover {
  background-color: #45a049;
}

/* Средний плеер */
.middlePlayer {
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 15px;
  background-color: #ececec;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.middlePlayer img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.middlePlayer .trackInfo {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  text-align: center;
}

.middlePlayer .controls {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.middlePlayer button {
  padding: 10px 20px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.middlePlayer button:hover {
  background-color: #1e88e5;
}
//
///* Полный плеер */
//.fullPlayer {
//  display: flex;
//  flex-direction: column;
//  width: 600px;
//  padding: 20px;
//  background-color: #f8f8f8;
//  border-radius: 10px;
//  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//}
//
//.fullPlayer img {
//  width: 150px;
//  height: 150px;
//  border-radius: 10px;
//}
//
//.fullPlayer .trackInfo {
//  margin-top: 15px;
//  font-size: 18px;
//  color: #333;
//  text-align: center;
//}
//
//.fullPlayer .controls {
//  display: flex;
//  justify-content: space-around;
//  margin-top: 20px;
//}
//
//.fullPlayer button {
//  padding: 10px 25px;
//  background-color: #ff9800;
//  color: white;
//  border: none;
//  border-radius: 5px;
//  cursor: pointer;
//}
//
//.fullPlayer button:hover {
//  background-color: #fb8c00;
//}
//
//.fullPlayer .timeline {
//  margin-top: 20px;
//  width: 100%;
//  display: flex;
//  justify-content: center;
//}
//
//.fullPlayer .timeline input[type="range"] {
//  width: 80%;
//  cursor: pointer;
//}
@media screen and (min-width: 550px){
  @media screen and (min-height: 550px) {
    .full {
      width: 100%;
      height: 100%;
      //background: linear-gradient(to right, #17466D, #442671);
      //border-radius: 27px 27px 0 0;
      display: flex;
      justify-content: space-between;
      z-index: 1000;
      border: 4px solid #FFF;
      padding: 10px 20px 20px 20px;

      .fullplayer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 20px;

        .fullplayerleft {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .fullplayerup {
            width: calc(100% - 0px);
            //padding: 0 30px 0 70px;
            .title {
              color: #FFF;
              margin-top: 3px;
              margin-left: 0px;
              width: 100%;
              overflow: hidden;
              //height: 20px;
              font
              .text {
                white-space: nowrap; /* Оставляем текст в одну строку */
                box-sizing: border-box;
                position: relative;

                /* Анимация бегущей строки */
                animation: marquee 5s linear infinite; /* Анимация длится 10 секунд, можно изменить */
              }
            }

            @keyframes marquee {
              0% {
                transform: translateX(20%); /* Начинаем за пределами контейнера справа */
                opacity: 0;
              }
              5% {
                transform: translateX(0%); /* Начинаем за пределами контейнера справа */
                opacity: 1;
              }
              95% {
                transform: translateX(-29%); /* Заканчиваем за пределами контейнера слева */
                opacity: 1;
              }
              100% {
                transform: translateX(-30%); /* Заканчиваем за пределами контейнера слева */
                opacity: 0;
              }
            }

            .startstop {

              margin-top: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              i {
                color: #FFF;
                font-size: 20pt;
                margin-right: 30px;
                width: 40px;
                cursor: pointer;
              }

              i:hover {
                //color: #9c6ac5;
                opacity: 0.7;
              }

              i:focus {
                box-shadow: 4px 4px 4px #FFF;
              }

              .equalizer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;

                .one {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF6868, #1139FF);
                }

                .oneactive {
                  animation: oneactives 2s linear infinite;
                }

                @keyframes oneactives {
                  0% {
                    height: 15px;
                  }
                  50% {
                    height: 30px;
                  }
                  100% {
                    height: 15px;
                  }
                }

                .two {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF593C, #4968FF);
                }

                .twoactive {
                  animation: twoactives 1s linear infinite;
                }

                @keyframes twoactives {
                  0% {
                    height: 40px;
                  }
                  60% {
                    height: 12px;
                  }
                  100% {
                    height: 40px;
                  }
                }

                .three {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF7D5C, #5188FF);
                }

                .threeactive {
                  animation: threeactives 0.6s linear infinite;
                }

                @keyframes threeactives {
                  0% {
                    height: 40px;
                  }
                  60% {
                    height: 12px;
                  }
                  100% {
                    height: 40px;
                  }
                }

                .four {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF8061, #54B8FF);
                }

                .fouractive {
                  animation: fouractives 0.6s linear infinite;
                }

                @keyframes fouractives {
                  0% {
                    height: 50px;
                  }
                  60% {
                    height: 20px;
                  }
                  100% {
                    height: 50px;
                  }
                }

                .five {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FFAF53, #56D2FF);
                }

                .fiveactive {
                  animation: fiveactives 2s linear infinite;
                }

                @keyframes fiveactives {
                  0% {
                    height: 12px;
                  }
                  30% {
                    height: 20px;
                  }
                  60% {
                    height: 40px;
                  }
                  80% {
                    height: 20px;
                  }
                  100% {
                    height: 12px;
                  }
                }
              }
            }
          }

          .timeline {
            width: 100%;
            margin-top: 10px;
            margin-left: 0px;
            display: flex;
            justify-content: flex-start;
          }

          .timeline input[type="range"] {
            width: 60%;
            height: 3px;
            cursor: pointer;
          }
        }


      }

      .activemusic {
        width: calc(50% - 20px);
        height: 100%;
        display: flex;
        position: relative;

        .left {
          width: 250px;
          height: 100px;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          background-size: cover;
          background-position: top center;
          box-shadow: -4px -4px 10px rgba(0, 0, 0, 0.5);
          border-radius: 10px 10px 0 0;
        }

        .center {
          width: 350px;
          height: 150px;
          position: absolute;
          bottom: 0;
          left: 175px;
          z-index: 3;
          background-size: cover;
          background-position: top center;
          box-shadow: 4px -4px 30px rgba(255, 0, 0, 0.5);
          border-radius: 10px 10px 0 0;
        }

        .right {
          width: 250px;
          height: 100px;
          position: absolute;
          bottom: 0;
          left: 400px;
          z-index: 2;
          background-size: cover;
          background-position: top center;
          box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
          border-radius: 10px 10px 0 0;
        }
      }

    }
  }
  @media screen and (max-height: 550px) {
    .full {
      width: 100%;
      height: 100%;
      //background: linear-gradient(to right, #17466D, #442671);
      //border-radius: 27px 27px 0 0;
      display: flex;
      justify-content: space-between;
      z-index: 1000;
      //border: 4px solid #FFF;
      padding: 10px 0 0 0;

      .fullplayer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 20px;

        .fullplayerleft {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .fullplayerup {
            width: calc(100% - 0px);
            //padding: 0 30px 0 70px;
            .title {
              color: #FFF;
              margin-top: 3px;
              margin-left: 0px;
              width: 100%;
              overflow: hidden;
              //height: 20px;
              font
              .text {
                white-space: nowrap; /* Оставляем текст в одну строку */
                box-sizing: border-box;
                position: relative;

                /* Анимация бегущей строки */
                animation: marquee 5s linear infinite; /* Анимация длится 10 секунд, можно изменить */
              }
            }

            @keyframes marquee {
              0% {
                transform: translateX(20%); /* Начинаем за пределами контейнера справа */
                opacity: 0;
              }
              5% {
                transform: translateX(0%); /* Начинаем за пределами контейнера справа */
                opacity: 1;
              }
              95% {
                transform: translateX(-29%); /* Заканчиваем за пределами контейнера слева */
                opacity: 1;
              }
              100% {
                transform: translateX(-30%); /* Заканчиваем за пределами контейнера слева */
                opacity: 0;
              }
            }

            .startstop {

              margin-top: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              i {
                color: #FFF;
                font-size: 20pt;
                margin-right: 30px;
                width: 40px;
                cursor: pointer;
              }

              i:hover {
                //color: #9c6ac5;
                opacity: 0.7;
              }

              i:focus {
                box-shadow: 4px 4px 4px #FFF;
              }

              .equalizer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;

                .one {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF6868, #1139FF);
                }

                .oneactive {
                  animation: oneactives 2s linear infinite;
                }

                @keyframes oneactives {
                  0% {
                    height: 15px;
                  }
                  50% {
                    height: 30px;
                  }
                  100% {
                    height: 15px;
                  }
                }

                .two {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF593C, #4968FF);
                }

                .twoactive {
                  animation: twoactives 1s linear infinite;
                }

                @keyframes twoactives {
                  0% {
                    height: 40px;
                  }
                  60% {
                    height: 12px;
                  }
                  100% {
                    height: 40px;
                  }
                }

                .three {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF7D5C, #5188FF);
                }

                .threeactive {
                  animation: threeactives 0.6s linear infinite;
                }

                @keyframes threeactives {
                  0% {
                    height: 40px;
                  }
                  60% {
                    height: 12px;
                  }
                  100% {
                    height: 40px;
                  }
                }

                .four {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF8061, #54B8FF);
                }

                .fouractive {
                  animation: fouractives 0.6s linear infinite;
                }

                @keyframes fouractives {
                  0% {
                    height: 50px;
                  }
                  60% {
                    height: 20px;
                  }
                  100% {
                    height: 50px;
                  }
                }

                .five {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FFAF53, #56D2FF);
                }

                .fiveactive {
                  animation: fiveactives 2s linear infinite;
                }

                @keyframes fiveactives {
                  0% {
                    height: 12px;
                  }
                  30% {
                    height: 20px;
                  }
                  60% {
                    height: 40px;
                  }
                  80% {
                    height: 20px;
                  }
                  100% {
                    height: 12px;
                  }
                }
              }
            }
          }

          .timeline {
            width: 100%;
            margin-top: 10px;
            margin-left: 0px;
            display: flex;
            justify-content: flex-start;
          }

          .timeline input[type="range"] {
            width: 60%;
            height: 3px;
            cursor: pointer;
          }
        }


      }

      .activemusic {
        width: calc(50% - 20px);
        height: 100%;
        display: flex;
        position: relative;

        .left {
          width: 250px;
          height: 100px;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          background-size: cover;
          background-position: top center;
          box-shadow: -4px -4px 10px rgba(0, 0, 0, 0.5);
          border-radius: 10px 10px 0 0;
        }

        .center {
          width: 350px;
          height: 150px;
          position: absolute;
          bottom: 0;
          left: 175px;
          z-index: 3;
          background-size: cover;
          background-position: top center;
          box-shadow: 4px -4px 30px rgba(255, 0, 0, 0.5);
          border-radius: 10px 10px 0 0;
        }

        .right {
          width: 250px;
          height: 100px;
          position: absolute;
          bottom: 0;
          left: 400px;
          z-index: 2;
          background-size: cover;
          background-position: top center;
          box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
          border-radius: 10px 10px 0 0;
        }
      }

    }
  }
}
@media screen and (max-width: 550px){
  @media screen and (min-height: 850px) {
    .full {
      width: 100%;
      height: 100%;
      //background: linear-gradient(to right, #17466D, #442671);
      //border-radius: 27px 27px 0 0;
      display: flex;
      justify-content: space-between;
      z-index: 1000;
      border: 4px solid #FFF;
      padding: 10px 20px 20px 20px;

      .fullplayer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 20px;

        .fullplayerleft {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .fullplayerup {
            width: calc(100% - 0px);
            //padding: 0 30px 0 70px;
            .title {
              color: #FFF;
              margin-top: 3px;
              margin-left: 0px;
              width: 100%;
              overflow: hidden;
              //height: 20px;
              font
              .text {
                white-space: nowrap; /* Оставляем текст в одну строку */
                box-sizing: border-box;
                position: relative;

                /* Анимация бегущей строки */
                animation: marquee 5s linear infinite; /* Анимация длится 10 секунд, можно изменить */
              }
            }

            @keyframes marquee {
              0% {
                transform: translateX(20%); /* Начинаем за пределами контейнера справа */
                opacity: 0;
              }
              5% {
                transform: translateX(0%); /* Начинаем за пределами контейнера справа */
                opacity: 1;
              }
              95% {
                transform: translateX(-29%); /* Заканчиваем за пределами контейнера слева */
                opacity: 1;
              }
              100% {
                transform: translateX(-30%); /* Заканчиваем за пределами контейнера слева */
                opacity: 0;
              }
            }

            .startstop {

              margin-top: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              i {
                color: #FFF;
                font-size: 20pt;
                margin-right: 30px;
                width: 40px;
                cursor: pointer;
              }

              i:hover {
                //color: #9c6ac5;
                opacity: 0.7;
              }

              i:focus {
                box-shadow: 4px 4px 4px #FFF;
              }

              .equalizer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;

                .one {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF6868, #1139FF);
                }

                .oneactive {
                  animation: oneactives 2s linear infinite;
                }

                @keyframes oneactives {
                  0% {
                    height: 15px;
                  }
                  50% {
                    height: 30px;
                  }
                  100% {
                    height: 15px;
                  }
                }

                .two {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF593C, #4968FF);
                }

                .twoactive {
                  animation: twoactives 1s linear infinite;
                }

                @keyframes twoactives {
                  0% {
                    height: 40px;
                  }
                  60% {
                    height: 12px;
                  }
                  100% {
                    height: 40px;
                  }
                }

                .three {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF7D5C, #5188FF);
                }

                .threeactive {
                  animation: threeactives 0.6s linear infinite;
                }

                @keyframes threeactives {
                  0% {
                    height: 40px;
                  }
                  60% {
                    height: 12px;
                  }
                  100% {
                    height: 40px;
                  }
                }

                .four {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF8061, #54B8FF);
                }

                .fouractive {
                  animation: fouractives 0.6s linear infinite;
                }

                @keyframes fouractives {
                  0% {
                    height: 50px;
                  }
                  60% {
                    height: 20px;
                  }
                  100% {
                    height: 50px;
                  }
                }

                .five {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FFAF53, #56D2FF);
                }

                .fiveactive {
                  animation: fiveactives 2s linear infinite;
                }

                @keyframes fiveactives {
                  0% {
                    height: 12px;
                  }
                  30% {
                    height: 20px;
                  }
                  60% {
                    height: 40px;
                  }
                  80% {
                    height: 20px;
                  }
                  100% {
                    height: 12px;
                  }
                }
              }
            }
          }

          .timeline {
            width: 100%;
            margin-top: 10px;
            margin-left: 0px;
            display: flex;
            justify-content: flex-start;
          }

          .timeline input[type="range"] {
            width: 60%;
            height: 3px;
            cursor: pointer;
          }
        }


      }

      .activemusic {
        width: calc(50% - 20px);
        height: 100%;
        display: flex;
        position: relative;

        .left {
          width: 250px;
          height: 100px;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          background-size: cover;
          background-position: top center;
          box-shadow: -4px -4px 10px rgba(0, 0, 0, 0.5);
          border-radius: 10px 10px 0 0;
        }

        .center {
          width: 350px;
          height: 150px;
          position: absolute;
          bottom: 0;
          left: 175px;
          z-index: 3;
          background-size: cover;
          background-position: top center;
          box-shadow: 4px -4px 30px rgba(255, 0, 0, 0.5);
          border-radius: 10px 10px 0 0;
        }

        .right {
          width: 250px;
          height: 100px;
          position: absolute;
          bottom: 0;
          left: 400px;
          z-index: 2;
          background-size: cover;
          background-position: top center;
          box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
          border-radius: 10px 10px 0 0;
        }
      }

    }
  }
  @media screen and (max-height: 850px) {
    .full {
      width: 100%;
      height: 100%;
      //background: linear-gradient(to right, #17466D, #442671);
      //border-radius: 27px 27px 0 0;
      display: flex;
      justify-content: space-between;
      z-index: 1000;
      //border: 4px solid #FFF;
      padding: 10px 0 0 0;

      .fullplayer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 20px;

        .fullplayerleft {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .fullplayerup {
            width: calc(100% - 0px);
            //padding: 0 30px 0 70px;
            .title {
              color: #FFF;
              margin-top: 3px;
              margin-left: 0px;
              width: 100%;
              overflow: hidden;
              //height: 20px;
              font
              .text {
                white-space: nowrap; /* Оставляем текст в одну строку */
                box-sizing: border-box;
                position: relative;

                /* Анимация бегущей строки */
                animation: marquee 5s linear infinite; /* Анимация длится 10 секунд, можно изменить */
              }
            }

            @keyframes marquee {
              0% {
                transform: translateX(20%); /* Начинаем за пределами контейнера справа */
                opacity: 0;
              }
              5% {
                transform: translateX(0%); /* Начинаем за пределами контейнера справа */
                opacity: 1;
              }
              95% {
                transform: translateX(-29%); /* Заканчиваем за пределами контейнера слева */
                opacity: 1;
              }
              100% {
                transform: translateX(-30%); /* Заканчиваем за пределами контейнера слева */
                opacity: 0;
              }
            }

            .startstop {

              margin-top: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              i {
                color: #FFF;
                font-size: 12pt;
                margin-right: 30px;
                width: 40px;
                cursor: pointer;
              }

              i:hover {
                //color: #9c6ac5;
                opacity: 0.7;
              }

              i:focus {
                box-shadow: 4px 4px 4px #FFF;
              }

              .equalizer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;

                .one {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF6868, #1139FF);
                }

                .oneactive {
                  animation: oneactives 2s linear infinite;
                }

                @keyframes oneactives {
                  0% {
                    height: 15px;
                  }
                  50% {
                    height: 30px;
                  }
                  100% {
                    height: 15px;
                  }
                }

                .two {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF593C, #4968FF);
                }

                .twoactive {
                  animation: twoactives 1s linear infinite;
                }

                @keyframes twoactives {
                  0% {
                    height: 40px;
                  }
                  60% {
                    height: 12px;
                  }
                  100% {
                    height: 40px;
                  }
                }

                .three {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF7D5C, #5188FF);
                }

                .threeactive {
                  animation: threeactives 0.6s linear infinite;
                }

                @keyframes threeactives {
                  0% {
                    height: 40px;
                  }
                  60% {
                    height: 12px;
                  }
                  100% {
                    height: 40px;
                  }
                }

                .four {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FF8061, #54B8FF);
                }

                .fouractive {
                  animation: fouractives 0.6s linear infinite;
                }

                @keyframes fouractives {
                  0% {
                    height: 50px;
                  }
                  60% {
                    height: 20px;
                  }
                  100% {
                    height: 50px;
                  }
                }

                .five {
                  margin: 0 2px;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: linear-gradient(to bottom, #FFAF53, #56D2FF);
                }

                .fiveactive {
                  animation: fiveactives 2s linear infinite;
                }

                @keyframes fiveactives {
                  0% {
                    height: 12px;
                  }
                  30% {
                    height: 20px;
                  }
                  60% {
                    height: 40px;
                  }
                  80% {
                    height: 20px;
                  }
                  100% {
                    height: 12px;
                  }
                }
              }
            }
          }

          .timeline {
            width: 100%;
            margin-top: 10px;
            margin-left: 0px;
            display: flex;
            justify-content: flex-start;
          }

          .timeline input[type="range"] {
            width: 60%;
            height: 3px;
            cursor: pointer;
          }
        }


      }

      .activemusic {
        width: calc(50% - 20px);
        height: 100%;
        display: flex;
        position: relative;

        .left {
          width: 250px;
          height: 100px;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          background-size: cover;
          background-position: top center;
          box-shadow: -4px -4px 10px rgba(0, 0, 0, 0.5);
          border-radius: 10px 10px 0 0;
        }

        .center {
          width: 350px;
          height: 150px;
          position: absolute;
          bottom: 0;
          left: 175px;
          z-index: 3;
          background-size: cover;
          background-position: top center;
          box-shadow: 4px -4px 30px rgba(255, 0, 0, 0.5);
          border-radius: 10px 10px 0 0;
        }

        .right {
          width: 250px;
          height: 100px;
          position: absolute;
          bottom: 0;
          left: 400px;
          z-index: 2;
          background-size: cover;
          background-position: top center;
          box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
          border-radius: 10px 10px 0 0;
        }
      }

    }
  }
}

.bodymain{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  font-family: "Montserrat";
  background-color: #F1F1F1;

  .main{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-image: url("../imgs/landback.svg");
    background-size: cover;
    background-position: top center;
    margin-top: -4px;


    .container{
      width: 100%;
      max-width: 1920px;
      height: 100%;
      min-height: 100vh;
      background-image: url("../imgs/land.png");
      background-size: cover;
      background-position: top center;
      margin-top: -2px;
    }
  }

}
@media screen and (min-width: 1600px){
  .main{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(100vh - 100px);
    background-color: rgba(0,0,0,0.8);
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0px 0px 0px;
    z-index: 10;
    @media screen and (min-width: 900px){
      .backclose{
        width: 100%;
        height: 100%;
        position: absolute;
        .closes{
          font-size: 20pt;
          position: fixed;
          top: 30px;
          right: 30px;
          cursor: pointer;
          border: 1px solid #FFFFFF;
          padding: 10px 30px;
          color: #FFFFFF;
          transition: 0.2s;
        }
        .closes:hover{
          border: 1px solid #F1F1F1;
          color: #CCC;
          transition: 0.2s;
        }
        .logo{
          font-size: 20pt;
          position: fixed;
          top: 30px;
          left: 30px;
          img{
            width: 200px;
          }
        }

      }
    }
    @media screen and (max-width: 900px){
      .backclose{
        width: 100%;
        height: 100%;
        position: absolute;
        .closes{
          font-size: 16pt;
          position: fixed;
          top: 30px;
          right: 20px;
          cursor: pointer;
          border: 1px solid #FFFFFF;
          padding: 10px 30px;
          color: #FFFFFF;
          transition: 0.2s;
        }
        .closes:hover{
          border: 1px solid #F1F1F1;
          color: #CCC;
          transition: 0.2s;
        }
        .logo{
          font-size: 20pt;
          position: fixed;
          top: 30px;
          left: 20px;
          img{
            width: 150px;
          }
        }

      }
    }

    .content{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
@media screen and (min-width: 1100px) and (max-width: 1600px){
  .main{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(100vh - 100px);
    background-color: rgba(0,0,0,0.8);
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0px 0px 0px;
    z-index: 10;
    @media screen and (min-width: 1200px){
      .backclose{
        width: 100%;
        height: 100%;
        position: absolute;
        .closes{
          font-size: 20pt;
          position: fixed;
          top: 30px;
          right: 30px;
          cursor: pointer;
          border: 1px solid #FFFFFF;
          padding: 10px 30px;
          color: #FFFFFF;
          transition: 0.2s;
        }
        .closes:hover{
          border: 1px solid #F1F1F1;
          color: #CCC;
          transition: 0.2s;
        }
        .logo{
          font-size: 20pt;
          position: fixed;
          top: 30px;
          left: 30px;
          img{
            width: 110px;
          }
        }

      }
    }
    @media screen and (max-width: 1200px){
      .backclose{
        width: 100%;
        height: 100%;
        position: absolute;
        .closes{
          font-size: 16pt;
          position: fixed;
          top: 30px;
          right: 20px;
          cursor: pointer;
          border: 1px solid #FFFFFF;
          padding: 10px 30px;
          color: #FFFFFF;
          transition: 0.2s;
        }
        .closes:hover{
          border: 1px solid #F1F1F1;
          color: #CCC;
          transition: 0.2s;
        }
        .logo{
          font-size: 20pt;
          position: fixed;
          top: 30px;
          left: 20px;
          img{
            //width: 150px;
            height: 80px;
          }
        }

      }
    }

    .content{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
@media screen and (min-width: 600px) and (max-width: 1100px){
  .main{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(100vh - 100px);
    background-color: rgba(0,0,0,0.8);
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0px 0px 0px;
    z-index: 10;
    @media screen and (min-width: 1100px){
      .backclose{
        width: 100%;
        height: 100%;
        position: absolute;
        .closes{
          font-size: 20pt;
          position: fixed;
          top: 30px;
          right: 30px;
          cursor: pointer;
          border: 1px solid #FFFFFF;
          padding: 10px 30px;
          color: #FFFFFF;
          transition: 0.2s;
        }
        .closes:hover{
          border: 1px solid #F1F1F1;
          color: #CCC;
          transition: 0.2s;
        }
        .logo{
          font-size: 20pt;
          position: fixed;
          top: 30px;
          left: 30px;
          img{
            width: 200px;
          }
        }

      }
    }
    @media screen and (max-width: 1100px){
      .backclose{
        width: 100%;
        height: 100%;
        position: absolute;
        .closes{
          font-size: 16pt;
          position: fixed;
          top: 30px;
          right: 20px;
          cursor: pointer;
          border: 1px solid #FFFFFF;
          padding: 10px 30px;
          color: #FFFFFF;
          transition: 0.2s;
        }
        .closes:hover{
          border: 1px solid #F1F1F1;
          color: #CCC;
          transition: 0.2s;
        }
        .logo{
          font-size: 20pt;
          position: fixed;
          top: 30px;
          left: 20px;
          img{
            //width: 150px;
            height: 50px;
          }
        }

      }
    }

    .content{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
@media screen and (min-width: 100px) and (max-width: 600px){
  .main{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(100vh - 100px);
    background-color: rgba(0,0,0,0.8);
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0px 0px 0px;
    z-index: 10;
    @media screen and (min-width: 1100px){
      .backclose{
        width: 100%;
        height: 100%;
        position: absolute;
        .closes{
          font-size: 20pt;
          position: fixed;
          top: 30px;
          right: 30px;
          cursor: pointer;
          border: 1px solid #FFFFFF;
          padding: 10px 30px;
          color: #FFFFFF;
          transition: 0.2s;
        }
        .closes:hover{
          border: 1px solid #F1F1F1;
          color: #CCC;
          transition: 0.2s;
        }
        .logo{
          font-size: 20pt;
          position: fixed;
          top: 30px;
          left: 30px;
          img{
            width: 200px;
          }
        }

      }
    }
    @media screen and (max-width: 1100px){
      .backclose{
        width: 100%;
        height: 100%;
        position: absolute;
        .closes{
          font-size: 16pt;
          position: fixed;
          top: 30px;
          right: 20px;
          cursor: pointer;
          border: 1px solid #FFFFFF;
          padding: 10px 30px;
          color: #FFFFFF;
          transition: 0.2s;
        }
        .closes:hover{
          border: 1px solid #F1F1F1;
          color: #CCC;
          transition: 0.2s;
        }
        .logo{
          font-size: 20pt;
          position: fixed;
          top: 30px;
          left: 20px;
          img{
            //width: 150px;
            height: 50px;
          }
        }

      }
    }

    .content{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}



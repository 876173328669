
@media screen and (min-width: 550px){
  .main {
    display: none;
  }
}
@media screen and (min-width: 400px) and (max-width: 550px){
  .main {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 0;

    .title {
      color: #ffffff;
      width: calc(100% - 0px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30pt;
      text-transform: uppercase;
      margin-bottom: 20px;
      padding-left: 0px;
      opacity: 1;
      transform: translateX(0%);
      transition: opacity 0.6s ease, transform 0.6s ease;
      &.visibleFromLeft {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .slider {
      width: 334px;
      position: relative;
      opacity: 0;
      transform: translateY(100%);
      transition: opacity 0.6s ease, transform 0.6s ease;
      &.visibleFromBottom {
        opacity: 1;
        transform: translateY(0);
      }

      .listblock {
        width: 100%;
        overflow: hidden;
      }

      .slidesContainer {
        display: flex;
      }

      .singer {
        margin: 0 18px;
        flex-shrink: 0;
        cursor: pointer;
        padding-top: 20px;

        z-index: 1;
        .cover {
          width: 100%;
          height: 300px;
          background-size: cover;
          background-position: center center;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(255, 255, 255, 0.8);
          font-size: 50px;
          z-index: 500;
        }
        .cover:hover{
          box-shadow: 0px 0px 20px rgb(132, 86, 201);
        }

        .name {
          margin-top: 10px;
          text-align: center;
          color: #ffffff;
          font-size: 18pt;
        }
      }

      .more {
        min-width: 300px;
        height: 420px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20pt;
        color: #FFF;
      }

      .tumbler {
        position: absolute;
        bottom: -120px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);

        .left,
        .right {
          cursor: pointer;
          padding: 20px;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 50%;
        }

        i {
          font-size: 24px;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    .bid{
      width: calc(100% - 40px);
      margin: 120px 20px 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      color: #FFFFFF;

      .text{
        font-size: 16pt;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn{
        padding: 30px 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        margin-top: 20px;
        background-color: #B784FC;
        font-size: 20pt;
        cursor: pointer;
        transition: 0.2s;
      }
      .btn:hover{
        background-color: rgba(183,132,252,0.8);
        transition: 0.2s;
      }
    }

  }
}

@media screen and (max-width: 399px){
  .main {
    display: none;
  }
}